import React from "react";
import AreaChart from "../../Common/Charts/AreaChart1/AreaChart";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import "./PowerArea.css";
import GuageChart1 from "../../Common/Charts/GaugeChart/GaugeChart1";
import AreaChart1 from "../../Common/Charts/AreaChart1/AreaChart1";
import BarGraph from "../../Common/Charts/BarGraph/BarGraph";

export default function PowerArea(props) {
  return (
    <>
      {props.activeTotalKW && (
        <div className="activestatusmain">
          <div className="tablegauge">
            <table className="tableMain">
              <thead>
                {props.activeTotalKW && (
                  <tr>
                    {props.activeTotalKW && <th colSpan={2}>GENERATOR</th>}
                  </tr>
                )}
                {props.activeKW && (
                  <tr>
                    {props.activeKW && <td>Power KW</td>}
                    {props.activeKW && <td>{props.activeKW}</td>}
                  </tr>
                )}
                {props.activeTotalKW && (
                  <tr>
                    {props.activeTotalKW && <td>Total KW/h</td>}
                    {props.activeTotalKW && <td>{props.activeTotalKW}</td>}
                  </tr>
                )}
                {props.activeCurrent_3P && (
                  <tr>
                    {props.activeCurrent_3P && <td>3-Phase A</td>}
                    {props.activeCurrent_3P && (
                      <td>{props.activeCurrent_3P}</td>
                    )}
                  </tr>
                )}
                {props.activeVoltage_3P && (
                  <tr>
                    {props.activeVoltage_3P && <td>3-Phase V</td>}
                    {props.activeVoltage_3P && (
                      <td>{props.activeVoltage_3P}</td>
                    )}
                  </tr>
                )}
                {props.activeFrequency && (
                  <tr>
                    <td>Frequency</td>
                    <td>{props.activeFrequency}</td>
                  </tr>
                )}
                {props.activeTotalPowerFactor && (
                  <tr>
                    {props.activeTotalPowerFactor && <td>Power Factor</td>}
                    {props.activeTotalPowerFactor && (
                      <td>{props.activeTotalPowerFactor}</td>
                    )}
                  </tr>
                )}
                {props.activeGenStatusLoad && (
                  <>
                    {props.activeGenStatusLoad == "ON LOAD" ? (
                      <tr style={{ color: "green" }}>
                        {props.activeGenStatusLoad && <td>Generator Status</td>}
                        {props.activeGenStatusLoad && (
                          <td>{props.activeGenStatusLoad}</td>
                        )}
                      </tr>
                    ) : (
                      <tr>
                        {props.activeGenStatusLoad && <td>Generator Status</td>}
                        {props.activeGenStatusLoad && (
                          <td>{props.activeGenStatusLoad}</td>
                        )}
                      </tr>
                    )}
                  </>
                )}
                {props.activeWapda && (
                  <>
                    {props.activeWapda == "ON" ? (
                      <tr style={{ color: "green" }}>
                        {props.activeWapda && <td>WAPDA</td>}
                        {props.activeWapda && <td>{props.activeWapda}</td>}
                      </tr>
                    ) : (
                      <tr>
                        {props.activeWapda && <td>WAPDA</td>}
                        {props.activeWapda && <td>{props.activeWapda}</td>}
                      </tr>
                    )}
                  </>
                )}
              </thead>
            </table>
            <table className="tableMain">
              <thead>
                <tr>{props.activeTotalFuel && <th colSpan={2}>FUEL</th>}</tr>
                {props.activeTotalFuel && (
                  <tr>
                    <td>Total Fuel Consumed Ltr</td>
                    <td>{props.activeTotalFuel}</td>
                  </tr>
                )}
                {props.activeAvailableFuel && (
                  <tr>
                    {props.activeAvailableFuel && <td>Available Fuel Ltr</td>}
                    {props.activeAvailableFuel && (
                      <td>{props.activeAvailableFuel}</td>
                    )}
                  </tr>
                )}
                {/* {props.activeFuelFlow && (
                  <tr>
                    <td>Fuel Flow Ltr</td>
                    <td>{props.activeFuelFlow}</td>
                  </tr>
                )} */}
                {props.activeFuelLevel && (
                  <tr>
                    <td>Fuel Level %</td>
                    <td>{props.activeFuelLevel}</td>
                  </tr>
                )}
                {props.activeFuelTemperature && (
                  <tr>
                    <td>Fuel Temperature °C</td>
                    <td>{props.activeFuelTemperature}</td>
                  </tr>
                )}
                {props.activeTankVolume && (
                  <tr>
                    <td>Tank Volume Ltr</td>
                    <td>{props.activeTankVolume}</td>
                  </tr>
                )}
              </thead>
            </table>
          </div>

          <div className="areachart">
            {props.activeChartChk && (
              <>
                <div className="chartunitgraph">
                  {/* <div className="unitName">KW</div> */}
                  <AreaChart
                    labels={props.activestatusLabels}
                    KW={props.activestatusKW}
                    label1="KW"
                    label2="Fuel"
                    label3="Run Time"
                    Fuel={props.activestatusFuel}
                    GenRunTime={props.activestatusGenRunTime}
                  />
                </div>
                <div className="graphName">Time</div>
              </>
            )}
          </div>

          {/* <div className="areachart">
            {props.activeChartChk && (
              <>
                <div className="chartunitgraph">
                  <BarGraph
                    labels={props.activestatusLabels1}
                    KW={props.activestatusKW1}
                    label1 = "KW"
                    label2 = "Fuel"
                    label3 = "Run Time"
                    Fuel={props.activestatusFuel1}
                    GenRunTime={props.activestatusGenRunTime1}
                  />
                  
                </div>
                <div className="graphName">Time</div>
              </>
            )}
          </div> */}
        </div>
      )}
    </>
  );
}

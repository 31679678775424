import { DateRangePicker } from "rsuite";
import React, { useState, useEffect } from "react";
import "./MainDashboard.css";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import Header from "../Common/Header/Header";
// import GuageChart1 from "../Common/Charts/GaugeChart1/GuageChart1";
import Clock from "react-live-clock";
import { GetMeterData } from "../../Service/MeterApi";
// import Footer from "../Common/Footer/Footer";
// import Header2 from '../Common/Header/Header2';
// import AreaChart1 from '../Common/Charts/AreaChart1/AreaChart1';
// import AreaChart2 from "../Common/Charts/AreaChart1/AreaChart2";
// import io from 'socket.io-client';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import AreaChart1 from "../Common/Charts/AreaChart1/AreaChart1";
import ActiveStatus from "./ActiveStatus/ActiveStatus";
import TodayStatus from "./TodayStatus/TodayStatus";
import CurrentMonthStatus from "./CurrentMonth/CurrentMonthStatus";
import LastMonthStatus from "./LastMonth/LastMonthStatus";
import Footer from "../Common/Footer/Footer";
import PowerArea from "./PowerArea/PowerArea";
import HeaderDash from "./Header/HeaderDash";
import FuelArea from "./FuelArea/FuelArea";
import GeneratorArea from "./GeneratorArea/GeneratorArea";
import { CircularProgress } from "@mui/material";

export default function MainDashboard() {
  const [value, setValue] = useState([]);

  let new1 = moment(value[0])?.format("DD-MM-YYYY");
  let new2 = moment(value[1])?.format("DD-MM-YYYY");

  let sdate = moment(value[0])?.format("YYYY-MM-DD");
  let edate = moment(value[1])?.format("YYYY-MM-DD");

  let sendDate = {
    startDate: new1,
    endDate: new2,
  };
  let dateback = {
    startDate: sdate,
    endDate: edate,
  };
  const navigate = useNavigate();
  const location = useLocation();

  let MQTT_ID = new URLSearchParams(location.search).get("MQTT_ID");
  // let roomName = location?.state?.roomName
  const [meterDashData, setMeterDashData] = useState([]);
  const [loading, setLoading] = useState(false);
  let machineName = meterDashData[0]?.machineName;
  let getarray = meterDashData?.map((array) => {
    return array?.meterReadings;
  });
  let getarrayHour = meterDashData?.map((array) => {
    return array?.HourReadings;
  });

  let getarrayDay = meterDashData?.map((array) => {
    return array?.DayReadings;
  });
  let getarrayMonth = meterDashData?.map((array) => {
    return array?.MonthReadings;
  });
  let getarrayEvent = meterDashData?.map((array) => {
    return array?.EventReadings;
  });
  // console.log(getarrayDay[0]?.length);
  let getTotalKWDay = [];
  if (getarrayDay[0]?.[getarrayDay[0]?.length - 2]?.Total_KW) {
    getTotalKWDay =
      getarrayDay[0]?.[getarrayDay[0]?.length - 1]?.Total_KW -
      getarrayDay[0]?.[getarrayDay[0]?.length - 2]?.Total_KW;
  } else {
    getTotalKWDay = getarrayDay[0]?.[getarrayDay[0]?.length - 1]?.Total_KW - 0;
  }
  let getTotalFuelDay = [];
  if (getarrayDay[0]?.[getarrayDay[0]?.length - 2]?.Total_Fuel) {
    getTotalFuelDay =
      getarrayDay[0]?.[getarrayDay[0]?.length - 1]?.Total_Fuel -
      getarrayDay[0]?.[getarrayDay[0]?.length - 2]?.Total_Fuel;
  } else {
    getTotalFuelDay =
      getarrayDay[0]?.[getarrayDay[0]?.length - 1]?.Total_Fuel - 0;
  }
  let getTotalHoursDay = [];
  if (getarrayDay[0]?.[getarrayDay[0]?.length - 2]?.Run_Hours) {
    getTotalHoursDay =
      getarrayDay[0]?.[getarrayDay[0]?.length - 1]?.Run_Hours -
      getarrayDay[0]?.[getarrayDay[0]?.length - 2]?.Run_Hours;
  } else {
    getTotalHoursDay =
      getarrayDay[0]?.[getarrayDay[0]?.length - 1]?.Run_Hours - 0;
  }

  // Month Calculation
  let getTotalKWMonth = [];
  if (getarrayMonth[0]?.[getarrayMonth[0]?.length - 2]?.Total_KW) {
    getTotalKWMonth =
      getarrayMonth[0]?.[getarrayMonth[0]?.length - 1]?.Total_KW -
      getarrayMonth[0]?.[getarrayMonth[0]?.length - 2]?.Total_KW;
  } else {
    getTotalKWMonth =
      getarrayMonth[0]?.[getarrayMonth[0]?.length - 1]?.Total_KW - 0;
  }
  let getTotalFuelMonth = [];
  if (getarrayMonth[0]?.[getarrayMonth[0]?.length - 2]?.Total_Fuel) {
    getTotalFuelMonth =
      getarrayMonth[0]?.[getarrayMonth[0]?.length - 1]?.Total_Fuel -
      getarrayMonth[0]?.[getarrayMonth[0]?.length - 2]?.Total_Fuel;
  } else {
    getTotalFuelMonth =
      getarrayMonth[0]?.[getarrayMonth[0]?.length - 1]?.Total_Fuel - 0;
  }
  let getTotalHoursMonth = [];
  if (getarrayMonth[0]?.[getarrayMonth[0]?.length - 2]?.Run_Hours) {
    getTotalHoursMonth =
      getarrayMonth[0]?.[getarrayMonth[0]?.length - 1]?.Run_Hours -
      getarrayMonth[0]?.[getarrayMonth[0]?.length - 2]?.Run_Hours;
  } else {
    getTotalHoursMonth =
      getarrayMonth[0]?.[getarrayMonth[0]?.length - 1]?.Run_Hours - 0;
  }

  // Month Average Calculation
  let currentMonth =
    getarrayMonth[0]?.[getarrayMonth?.length - 1]?.datetimeMonth;
  // console.log("current month",currentMonth)
  let CurrentMonthDateFilter = meterDashData?.map((meter) => {
    return {
      ...meter,
      meterReadings: meter?.DayReadings.filter((val) => {
        let currenDate = val?.datetimeMonth;
        if (currenDate === currentMonth) {
          return true;
        } else {
          return false;
        }
      }),
    };
  });
  let readd = CurrentMonthDateFilter[0]?.meterReadings;
  let daylength = readd?.length;
  let AvgTotalKWMonth = getTotalKWMonth / daylength;
  let AvgTotalFuelMonth = getTotalFuelMonth / daylength;
  let AvgTotalHoursMonth = getTotalHoursMonth / daylength;

  // Last Month Calculation
  let getTotalKWLastMonth = [];
  if (getarrayMonth[0]?.[getarrayMonth[0]?.length - 3]?.Total_KW) {
    getTotalKWLastMonth =
      getarrayMonth[0]?.[getarrayMonth[0]?.length - 2]?.Total_KW -
      getarrayMonth[0]?.[getarrayMonth[0]?.length - 3]?.Total_KW;
  } else if (getarrayMonth[0]?.[getarrayMonth[0]?.length - 2]?.Total_KW) {
    getTotalKWLastMonth =
      getarrayMonth[0]?.[getarrayMonth[0]?.length - 2]?.Total_KW - 0;
  } else {
    getTotalKWLastMonth = 0;
  }
  let getTotalFuelLastMonth = [];
  if (getarrayMonth[0]?.[getarrayMonth[0]?.length - 3]?.Total_Fuel) {
    getTotalFuelLastMonth =
      getarrayMonth[0]?.[getarrayMonth[0]?.length - 2]?.Total_Fuel -
      getarrayMonth[0]?.[getarrayMonth[0]?.length - 3]?.Total_Fuel;
  } else if (getarrayMonth[0]?.[getarrayMonth[0]?.length - 2]?.Total_Fuel) {
    getTotalFuelLastMonth =
      getarrayMonth[0]?.[getarrayMonth[0]?.length - 2]?.Total_Fuel - 0;
  } else {
    getTotalFuelLastMonth = 0;
  }
  let getTotalHoursLastMonth = [];
  if (getarrayMonth[0]?.[getarrayMonth[0]?.length - 3]?.Run_Hours) {
    getTotalHoursLastMonth =
      getarrayMonth[0]?.[getarrayMonth[0]?.length - 2]?.Run_Hours -
      getarrayMonth[0]?.[getarrayMonth[0]?.length - 3]?.Run_Hours;
  } else if (getarrayMonth[0]?.[getarrayMonth[0]?.length - 2]?.Run_Hours) {
    getTotalHoursLastMonth =
      getarrayMonth[0]?.[getarrayMonth[0]?.length - 2]?.Run_Hours - 0;
  } else {
    getTotalHoursLastMonth = 0;
  }

  // Last Month Average Calculation
  let lastMonth = [];
  if (getarrayMonth[0]?.[getarrayMonth[0]?.length - 2]) {
    lastMonth = getarrayMonth[0]?.[getarrayMonth[0]?.length - 2]?.datetimeMonth;
  } else {
    lastMonth = false;
  }
  // console.log("Last month",lastMonth)
  let lastMonthDateFilter = meterDashData?.map((meter) => {
    return {
      ...meter,
      meterReadings: meter?.DayReadings?.filter((val) => {
        let currenDate = val?.datetimeMonth;
        if (currenDate === lastMonth) {
          return true;
        } else {
          return false;
        }
      }),
    };
  });
  let red = lastMonthDateFilter[0]?.meterReadings;
  let daylengthLastMonth = red?.length;
  // console.log("month dat length----", daylengthLastMonth);
  let AvgTotalKWLastMonth = [];
  let AvgTotalFuelLastMonth = [];
  let AvgTotalHoursLastMonth = [];

  if (daylengthLastMonth === 0) {
    AvgTotalKWLastMonth = 0;
    AvgTotalFuelLastMonth = 0;
    AvgTotalHoursLastMonth = 0;
  } else {
    AvgTotalKWLastMonth = getTotalKWLastMonth / daylengthLastMonth;
    AvgTotalFuelLastMonth = getTotalFuelLastMonth / daylengthLastMonth;
    AvgTotalHoursLastMonth = getTotalHoursLastMonth / daylengthLastMonth;
  }

  // this api is goes to get api of meters data
  const gettingMeterData = async () => {
    setLoading(true);
    let res = await GetMeterData(MQTT_ID);
    if (res.error != null) {
      toast.error(res.error);
    } else {
      setMeterDashData(res.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (MQTT_ID) {
      // setInterval(() => {
      gettingMeterData();
      // }, 250);
    }
  }, [MQTT_ID]);

  const settingDate = (event) => {
    if (event == null) {
      setValue([]);
    } else {
      setValue(event);
    }
  };

  // the function is use to find the date between start date and last date
  const findByDateDaily = () => {
    let startDate = dateback?.startDate;
    let endDate = dateback?.endDate;
    let sDate = new Date(startDate).getTime();
    let eDate = new Date(endDate).getTime();
    let epStartDate = new Date(sDate - 18000000).getTime();
    let epEndDate = new Date(eDate + 68000000).getTime();
    let filterDate = meterDashData?.map((meter) => {
      return {
        ...meter,
        meterReadings: meter?.EventReadings?.filter((val) => {
          let currenDate = new Date(val?.datetime).getTime();
          if (
            currenDate >= epStartDate &&
            currenDate <= epEndDate &&
            val?.Water_Temperature != 0
          ) {
            return true;
          } else {
            return false;
          }
        }),
      };
    });

    navigate("/dashboard/report", {
      state: { date: sendDate, roomName: machineName, filterDate: filterDate },
    });
  };

  const findByDateHourly = () => {
    let startDate = dateback?.startDate;
    let endDate = dateback?.endDate;
    let sDate = new Date(startDate).getTime();
    let eDate = new Date(endDate).getTime();
    let epStartDate = new Date(sDate - 18000000).getTime();
    let epEndDate = new Date(eDate + 68000000).getTime();
    let filterDate = meterDashData?.map((meter) => {
      return {
        ...meter,
        meterReadings: meter?.HourReadings?.filter((val) => {
          let currenDate = new Date(val?.datetime).getTime();
          if (currenDate >= epStartDate && currenDate <= epEndDate) {
            return true;
          } else {
            return false;
          }
        }),
      };
    });

    navigate("/dashboard/report", {
      state: { date: sendDate, roomName: machineName, filterDate: filterDate },
    });
  };

  // convet min to hours and minutes
  function toHoursAndMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return { hours, minutes };
  }


  return (
    <>
      {/* <Header
        timedate={
          <Clock
            format={"HH:mm:ss | DD-MM-YYYY"}
            ticking={true}
            timezone={"asia/Karachi"}
          />
        }
        DeviceID = {meterDashData[0]?.Device_ID}
        head = {"MRS9000 Metering & Reporting System"}
        roomName={machineName}
      /> */}
            {
        loading ? 
        <>
        <div className="loading">
        <CircularProgress />
        </div>
        </>
        :
        <>
      <HeaderDash
        roomName={machineName}
        genName={meterDashData[0]?.genName}
        deviceID={meterDashData[0]?.Device_ID}
        datetimeActiveStatus={
          getarray[0]?.[getarray[0]?.length - 1]?.date +
          "\t | \t" +
          getarray[0]?.[getarray[0]?.length - 1]?.time
        }
        dateFilter={
          <>
            <div className="datepiker">
              <DateRangePicker
                showOneCalendar
                placement="bottomStart"
                className="rangepiker"
                onChange={(event) => settingDate(event)}
                value={value}
                placeholder="Start Date ~ End Date"
                renderValue={(value) => {
                  return (
                    moment(value[0])?.format("DD-MM-YYYY") +
                    " ~ " +
                    moment(value[1])?.format("DD-MM-YYYY")
                  );
                }}
              />
              <button className="btnreport" onClick={findByDateDaily}>
                {" "}
                Report
              </button>
            </div>
          </>
        }
      />

      <div className="MainDashboardContainer">
        <div className="activetodaycontainer">
          <PowerArea
            gaugename={"Power KW"}
            val={getarrayHour[0]?.[getarrayHour[0]?.length - 1]?.KW}
            activeChartChk={getarrayHour[0]?.[getarrayHour[0]?.length - 1]?.KW}
            activestatusLabels={getarrayEvent[0]
              ?.slice(-25)
              ?.map((time) => moment(time?.dt).format("DD | HH:mm"))}
            activestatusKW={getarrayEvent[0]
              ?.slice(-25)
              ?.map((dataa) => dataa?.Gen_Power)}
            activestatusFuel={getarrayEvent[0]
              ?.slice(-25)
              ?.map((dataa) => dataa?.Available_Fuel)}
            activestatusGenRunTime={getarrayEvent[0]
              ?.slice(-25)
              ?.map((dataa) => dataa?.Gen_Runtime)}
            // activestatusLabels1={getarrayEvent[0]
            //   ?.slice(-12)
            //   ?.map((time) => moment(time?.dt).format("DD | HH:mm"))}
            // activestatusKW1={getarrayEvent[0]
            //   ?.slice(-12)
            //   ?.map((dataa) => dataa?.KW)}
            // activestatusFuel1={getarrayEvent[0]
            //   ?.slice(-12)
            //   ?.map((dataa) => dataa?.Available_Fuel)}
            // activestatusGenRunTime1={getarrayEvent[0]
            //   ?.slice(-12)
            //   ?.map((dataa) => dataa?.Gen_Runtime)}
            // Generator table
            activeKW={parseInt(
              getarray[0]?.[getarray[0]?.length - 1]?.KW
            ).toLocaleString()}
            activeTotalKW={parseInt(
              getarray[0]?.[getarray[0]?.length - 1]?.Total_KW
            ).toLocaleString()}
            activeCurrent_3P={parseInt(
              getarray[0]?.[getarray[0]?.length - 1]?.Current_3P
            ).toLocaleString()}
            activeVoltage_3P={parseInt(
              getarray[0]?.[getarray[0]?.length - 1]?.Voltage_3P
            ).toLocaleString()}
            activeFrequency={parseFloat(
              getarray[0]?.[getarray[0]?.length - 1]?.Frequency
            ).toFixed(1)}
            activeTotalPowerFactor={parseFloat(
              getarray[0]?.[getarray[0]?.length - 1]?.Total_PowerFactor
            ).toFixed(2)}
            activeGenStatusLoad={
              getarray[0]?.[getarray[0]?.length - 1]?.Generator_StatusLOAD
            }
            activeWapda={getarray[0]?.[getarray[0]?.length - 1]?.Wapda}
            activeAvailableFuel={parseInt(
              getarray[0]?.[getarray[0]?.length - 1]?.Available_Fuel
              ).toLocaleString()}
            // Fuel table
            activeTotalFuel={parseInt(
              getarray[0]?.[getarray[0]?.length - 1]?.Total_FuelAdd
            ).toLocaleString()}

            // activeFuelFlow={parseInt(
            //   getarray[0]?.[getarray[0]?.length - 1]?.Fuel_Flow
            // ).toLocaleString()}
            activeFuelLevel={parseInt(
              getarray[0]?.[getarray[0]?.length - 1]?.Fuel_Level
            ).toLocaleString()}
            activeFuelTemperature={parseInt(
              getarray[0]?.[getarray[0]?.length - 1]?.Fuel_Temperature
            ).toLocaleString()}
            activeTankVolume={parseInt(
              getarray[0]?.[getarray[0]?.length - 1]?.Tank_Volume
            ).toLocaleString()}
          />
          {/* <FuelArea 
          activeChartChk={
            getarrayHour[0]?.[getarrayHour[0]?.length - 1]?.Total_Fuel
          }
          activestatusLabels={getarrayHour[0]
            ?.slice(-24)
            ?.map((time) => time?.time)}
          activestatusKW={getarrayHour[0]
            ?.slice(-24)
            ?.map((dataa) => dataa?.totalFuelHourReduction)}

          activeTotalFuel={parseInt(
            getarray[0]?.[getarray[0]?.length - 1]?.Total_Fuel
          ).toLocaleString()}
          activeAvailableFuel={parseInt(
            getarray[0]?.[getarray[0]?.length - 1]?.Available_Fuel
          ).toLocaleString()}
          activeFuelFlow={parseInt(
            getarray[0]?.[getarray[0]?.length - 1]?.Fuel_Flow
          ).toLocaleString()}
          activeFuelLevel={parseInt(
            getarray[0]?.[getarray[0]?.length - 1]?.Fuel_Level
          ).toLocaleString()}
          activeFuelTemperature={parseInt(
            getarray[0]?.[getarray[0]?.length - 1]?.Fuel_Temperature
            ).toLocaleString()}
            activeTankVolume={parseInt(
              getarray[0]?.[getarray[0]?.length - 1]?.Tank_Volume
              ).toLocaleString()}
            /> */}

          {/* ------------------------------------------------ */}
          <GeneratorArea
            activeChartChk={
              getarrayHour[0]?.[getarrayHour[0]?.length - 1]?.Total_Fuel
            }
            activestatusLabels={getarrayEvent[0]
              ?.slice(-25)
              ?.map((time) => moment(time?.dt).format("DD | HH:mm"))}
            activestatusKW={getarrayEvent[0]
              ?.slice(-25)
              ?.map((dataa) => dataa?.Oil_Pressure)}
            activestatusFuel={getarrayEvent[0]
              ?.slice(-25)
              ?.map((dataa) => dataa?.Water_Temperature)}
            activeOilPressure={
              getarray[0]?.[getarray[0]?.length - 1]?.Oil_Pressure
            }
            activeTotalChk={
              (getarray[0]?.[getarray[0]?.length - 1]?.Total_KW)
            }
            activeTotal={
              parseInt(getarray[0]?.[getarray[0]?.length - 1]?.Gen_Power)
            }
            // activeAvailableFuel={parseInt(
            //   getarray[0]?.[getarray[0]?.length - 1]?.Available_Fuel
            //   ).toLocaleString()}
              activeFuelAvailableStart={parseInt(
                getarray[0]?.[getarray[0]?.length - 1]?.Available_Fuel_Start
              ).toLocaleString()}
              activeFuelAvailableEnd={parseInt(
                getarray[0]?.[getarray[0]?.length - 1]?.Available_Fuel_End
              ).toLocaleString()}
              activeRefueling={parseInt(
              getarray[0]?.[getarray[0]?.length - 1]?.Refueling
            ).toLocaleString()}
            activeGenRunTime={parseInt(
              getarray[0]?.[getarray[0]?.length - 1]?.Gen_Runtime
            ).toLocaleString()}
            activeWapdaOffMin={parseInt(
              getarray[0]?.[getarray[0]?.length - 1]?.Wapda_OFFTime
            ).toLocaleString()}


            activeWaterLevel={
              getarray[0]?.[getarray[0]?.length - 1]?.Water_Level
            }
            activeWaterTemp={
              getarray[0]?.[getarray[0]?.length - 1]?.Water_Temperature
            }
            activeBatteryVoltage={parseInt(
              getarray[0]?.[getarray[0]?.length - 1]?.Battery_Voltage
            ).toLocaleString()}
            // activeRunHours={`${
            //   toHoursAndMinutes(
            //     getarray[0]?.[getarray[0]?.length - 1]?.Run_Hours
            //   ).hours
            // } : ${
            //   toHoursAndMinutes(
            //     getarray[0]?.[getarray[0]?.length - 1]?.Run_Hours
            //   ).minutes
            // }  `}
            activeRunHours={(
                getarray[0]?.[getarray[0]?.length - 1]?.Run_Hours
              )}
            activeLidStatus={getarray[0]?.[getarray[0]?.length - 1]?.Lid_Status}
            activeGenStatus={
              getarray[0]?.[getarray[0]?.length - 1]?.Generator_Status
            }
          />

     

        </div>
      </div>
      </>
      }
      <ToastContainer />
    </>
  );
}

import React from "react";
import "./ReportMachineHour.css";
import Header from "../Common/Header/Header";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import { useNavigate, useLocation } from "react-router-dom";

const ReportMachine = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const date = location.state.date;
  const roomName = location.state.roomName;
  let array = location?.state?.filterDateHour;
  let ar = array[0]?.HourReadings;
  let arr = [].concat(ar).reverse();
  // console.log("this is the first array",arr[0])

  // console.log("This is the length of array",arr.length)
  // Get Total Values
  let arrLen = arr.length;
  let totalT = arr[0]?.total - arr[arr.length - 1]?.total;
  let totalAvg = totalT / arrLen;
  let linetemp = 0;
  let addlinetemp = arr?.map((data) => {
    return parseFloat(data?.linetemp);
  });
  for (var i in addlinetemp) {
    linetemp += addlinetemp[i];
  }
  let linetempavg = linetemp / arrLen;
  // console.log(linetempavg)

  const reportformat =
    "Jalal Sons\t" + roomName + "\t" + date.startDate + "\t\t" + date.endDate;
  const pdfbtn = () => {
    var doc = new jsPDF("l", "pt");
    var content = document.getElementById("reportid");
    doc.html(content, {
      callback: function (doc) {
        const pageCount = doc.internal.getNumberOfPages();
        doc.setFontSize(12);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          // doc.rect(20, 20, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 40, 'S');
          doc.text(
            "Page" + String(i) + " of " + String(pageCount),
            842 - 30,
            595 - 30,
            null,
            null,
            "right"
          );
        }
        doc.save(reportformat);
      },
    });
  };
  const close = () => {
    navigate(-1);
  };

  // convet min to hours and minutes
  function toHoursAndMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return { hours, minutes };
  }

  return (
    <>
      <div className="reportmain">
        <div className="report" id="reportid">
          <Header
            roomName={roomName}
            head={"MRS9000 GENERATOR SET HEALTH MONITORING WITH REPORTING"}
          />
          <div className="date">{arr[arr?.length - 1]?.date}</div>
          <table className="tablemain" id="tablee">
            <thead className="tablehead">
              <tr className="tablerow">
                {arr[arr.length - 1]?.date && (
                  <th className="tableh datetimetable">Time | Date</th>
                )}
                {arr[arr.length - 1]?.KW && (
                  <th className="tableh headtable">KW | Total KW </th>
                )}
                {arr[arr.length - 1]?.Fuel_Level && (
                  <th className="tableh headtable">Fuel | Total Ltr</th>
                )}
                {arr[arr.length - 1]?.Fuel_Flow && (
                  <th className="tableh headtable">Fuel Flow</th>
                )}
                {arr[arr.length - 1]?.Available_Fuel && (
                  <th className="tableh headtable">Available Fuel</th>
                )}
                {arr[arr.length - 1]?.Fuel_Temperature && (
                  <th className="tableh headtable">Fuel Temp</th>
                )}
                {arr[arr.length - 1]?.Tank_Volume && (
                  <th className="tableh headtable">Tank Volume</th>
                )}
                {arr[arr.length - 1]?.Frequency && (
                  <th className="tableh headtable">Frequency</th>
                )}
                {arr[arr.length - 1]?.Run_Hours && (
                  <th className="tableh headtable">Run Hours</th>
                )}
                {arr[arr.length - 1]?.Battery_Voltage && (
                  <th className="tableh headtable">Battery Voltage</th>
                )}
                {arr[arr.length - 1]?.Water_Temperature && (
                  <th className="tableh headtable">Water Temp °C </th>
                )}
                {arr[arr.length - 1]?.Oil_Pressure && (
                  <th className="tableh headtable">Oil Pressure Psi</th>
                )}
                {arr[arr.length - 1]?.Water_Level && (
                  <th className="tableh headtable">Water Level</th>
                )}
                {arr[arr.length - 1]?.Lid_Status && (
                  <th className="tableh headtable">Lid Status</th>
                )}
                {arr[arr.length - 1]?.Generator_Status && (
                  <th className="tableh genheadtable">Engine Status</th>
                )}
                {arr[arr.length - 1]?.Generator_StatusLOAD && (
                  <th className="tableh genheadtable">Gen Status</th>
                )}
              </tr>
            </thead>
            <tbody className="tablebody">
              {arr.map((ddd, i) => {
                return (
                  <tr className="tablerow" key={i}>
                    <>
                      <td className="tabled">
                        {ddd?.time} | {ddd?.date}
                      </td>
                      {arr[arr.length - 1]?.KW && (
                        <td className="tabled">
                          {parseInt(ddd?.KW).toLocaleString()} |{" "}
                          {parseInt(ddd?.Total_KW).toLocaleString()}{" "}
                        </td>
                      )}
                      {arr[arr.length - 1]?.Fuel_Level && (
                        <td className="tabled">
                          {parseInt(ddd?.Fuel_Level).toLocaleString()} |{" "}
                          {parseInt(ddd?.Total_Fuel).toLocaleString()}{" "}
                        </td>
                      )}
                      {arr[arr.length - 1]?.Fuel_Flow && (
                        <td className="tabled">
                          {parseInt(ddd?.Fuel_Flow).toLocaleString()}{" "}
                        </td>
                      )}
                      {arr[arr.length - 1]?.Available_Fuel && (
                        <td className="tabled">
                          {parseInt(ddd?.Available_Fuel).toLocaleString()}{" "}
                        </td>
                      )}
                      {arr[arr.length - 1]?.Fuel_Temperature && (
                        <td className="tabled">
                          {parseInt(ddd?.Fuel_Temperature).toLocaleString()}{" "}
                        </td>
                      )}
                      {arr[arr.length - 1]?.Tank_Volume && (
                        <td className="tabled">
                          {parseInt(ddd?.Tank_Volume).toLocaleString()}{" "}
                        </td>
                      )}
                      {arr[arr.length - 1]?.Frequency && (
                        <td className="tabled">
                          {parseFloat(ddd?.Frequency).toFixed(1)}
                        </td>
                      )}
                      {arr[arr.length - 1]?.Run_Hours && (
                        <td className="tabled">
                          {`${toHoursAndMinutes(ddd?.Run_Hours).hours} : ${
                            toHoursAndMinutes(ddd?.Run_Hours).minutes
                          }  `}{" "}
                        </td>
                      )}
                      {arr[arr.length - 1]?.Battery_Voltage && (
                        <td className="tabled">
                          {parseInt(ddd?.Battery_Voltage).toLocaleString()}{" "}
                        </td>
                      )}
                      {arr[arr.length - 1]?.Water_Temperature && (
                        <td className="tabled">{ddd?.Water_Temperature}</td>
                      )}
                      {arr[arr.length - 1]?.Oil_Pressure && (
                        <td className="tabled">{ddd?.Oil_Pressure} </td>
                      )}
                      {arr[arr.length - 1]?.Water_Level && (
                        <td className="tabled">{ddd?.Water_Level} </td>
                      )}
                      {arr[arr.length - 1]?.Lid_Status && (
                        <td className="tabled">
                          {parseInt(ddd?.Lid_Status).toLocaleString()}{" "}
                        </td>
                      )}
                      {arr[arr.length - 1]?.Generator_Status && (
                        <td className="tabled">{ddd?.Generator_Status}</td>
                      )}
                      {arr[arr.length - 1]?.Generator_StatusLOAD && (
                        <td className="tabled">{ddd?.Generator_StatusLOAD}</td>
                      )}
                    </>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="btnss">
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="excelbtn"
            table="tablee"
            filename={reportformat}
            sheet="tablexls"
            buttonText="Excel"
          />
          <button className="pdfbtn" onClick={pdfbtn}>
            Pdf
          </button>
          <button className="pdfbtn" onClick={close}>
            Close
          </button>
        </div>
      </div>
    </>
  );
};
export default ReportMachine;

import React from "react";
import "./HeaderDash.css";

import MenuProvider from "react-flexible-sliding-menu";
// import Menu from '../Charts/SlideableManu/Menu';
import Menu from "../../Common/Charts/SlideableManu/Menu";
import SlideableManu1 from "../../Common/Charts/SlideableManu/SlideableManu1";

const HeaderDash = (props) => {
  return (
    <>
      <div className="headerDashMain">
        <div className="sidemanubar">
          {/* <MenuProvider MenuComponent={Menu}> */}
          <MenuProvider MenuComponent={Menu} animation="push">
            <SlideableManu1 />
          </MenuProvider>
        </div>
        <div className="headeractivestatus">
          {/* <div className="avtivestatusname">Live Metering</div> */}
          <div className="datetimestatus">
            <span className="avtivestatusroomname">
              {props.roomName}
              <br />
              {props.genName}{" "}
              <span className="deviceID">({props.deviceID})</span>{" "}
            </span>
            {props.datetimeActiveStatus}
          </div>
          <div className="activestatuslogo">
            {props.dateFilter}
            {/* <div className="livestatusname">Live Status</div>
            <div className="livestatuslogo">
              <AutoGraphIcon />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderDash;

import './App.css';

// Toast Data
import { ToastContainer } from 'react-toastify';


import {
  BrowserRouter as Router,
  Navigate,
  Routes,
  Route,
  useLocation
} from 'react-router-dom';

// import DashboardMachineDataMain from './Components/MachineData/MachineData';
import MainDashboard from './Components/MainDashboard/MainDashboard'
import Department from './Components/Department/Department';
import ReportMachine from './Components/ReportMachine/ReportMachine';
import ReportMachineHour from './Components/ReportMachineHour/ReportMachineHour';
import Login from './Components/Auth/Login/Login';
import ProtedtedRoute from './Components/GlobalRoute/ProtedtedRoute';
import Error from './Components/PageNotFound/PageError';
import Footer from './Components/Common/Footer/Footer';

const ProtectedRoutes = () => {
  let location = useLocation()

  return (
    <>
      <div className="App">
        <Routes>
          <Route exact path='/login' element={<Login />}></Route>
          <Route exact path='/' element={<Navigate replace to='/login' />}></Route>
          {/* <Route exact path='/dashboard/Dep' element={<ProtedtedRoute Component={DashboardMachineDataMain} />}></Route> */}
          <Route exact path='/dashboard/Dep' element={<ProtedtedRoute Component={MainDashboard} />}></Route>
          <Route exact path='/area' element={<ProtedtedRoute Component={Department} />}></Route>
          <Route exact path='/dashboard/report' element={<ProtedtedRoute Component={ReportMachine} />}></Route>
          <Route exact path='/dashboard/reportHour' element={<ProtedtedRoute Component={ReportMachineHour} />}></Route>
          <Route path="*" element={<Error />} />
        </Routes>
      </div>
      {location.pathname !== "/login" && location.pathname !== "/dashboard/report" && <Footer comp={"Omni Telementory USA"} />}
    </>
  )
}

function App() {
  return (
    <>
      <Router>
        <ProtectedRoutes />
      </Router>
    </>
  );
}

<ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      // theme="dark"
      />

export default App;

import React from 'react'
import { Bar, Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export default function AreaChart1(props) {

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            stroke: {
                curve: 'smooth',
              },
            legend: {
                position: 'top',
            },
        },
        scales: {
            x: {
                ticks: {
                  font: {
                    size: 10,
                    family: "vazir",
                  },
                },
              },
            y: {
                ticks: {
                    font: {
                      size: 12,
                      family: "vazir",
                    },
                  },
                beginAtZero: true,
                // max: props.maxValueArea
            }
        }

    };
    const labels = props.labels
    // const labels = meterDashData.slice(-24)?.map((time) => time?.time)
    const label1 = props.label1;
    const label2 = props.label2;
    const label3 = props.label3;
    // const labelTemp = 'Temperature'
    // const labelHumid = 'Humidity'
    const data = {
        labels,
        datasets: [
            {
                fill: true,
                label: label1,
                //   data: [10,42,13,74,25,23,54,65],
                data: props.KW,
                borderColor: 'rgb(41, 128, 185 )',
                backgroundColor: 'rgba(169, 204, 227, 0.7)',
                pointRadius: 2, // Remove data points (dots)
                lineTension: 0.4,
                borderJoinStyle: 'round',
            },
            {
                label: label2,
                fill: true,
                // label: 'Temperature' + '\t' + meterDashData[meterDashData.length - 1]?.value?.temp + '°C ',
                // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
                //   data: [10,42,13,74,25,23,54,65],
                
                // data: meterDashData[0]?.meterReadings.slice(-24).map((ddd)=>ddd?.temp),
                data: props.Fuel,
                borderColor: 'rgb(211, 84, 0 )',
                backgroundColor: 'rgba(237, 187, 153, 0.7)',
                pointRadius: 2, // Remove data points (dots)
                lineTension: 0.4,
                borderJoinStyle: 'round',
            },
            {
                label: label3,
                fill: true,
                // label: 'Temperature' + '\t' + meterDashData[meterDashData.length - 1]?.value?.temp + '°C ',
                // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
                //   data: [10,42,13,74,25,23,54,65],
                
                // data: meterDashData[0]?.meterReadings.slice(-24).map((ddd)=>ddd?.temp),
                data: props.GenRunTime,
                borderColor: 'rgb(0, 128, 0)',
                backgroundColor: 'rgba(0, 128, 0,0.7)',
                pointRadius: 2, // Remove data points (dots)
                lineTension: 0.4,
                borderJoinStyle: 'round',
            },
            
        ],
    };
  return (
    <>
    <Line options={options} data={data} height={300} width={420}/>
    </>
  )
}
